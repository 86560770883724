import {urlpath} from '../api/api';
import {getUrlKey} from './getUrlKey';

const axios = require('axios');
const qs = require('query-string');
export const http = function() {
  axios.interceptors.request.use(
    function(config) {
      // config.baseURL = 'https://inf.huitouke.cn/';
      config.baseURL = urlpath;
      // config.baseURL = 'http://tinterface.huitouke.cn/';
      config.headers['Content-type'] =
        'application/x-www-form-urlencoded; charset=UTF-8';

      if (!config.data) {
        config.data = {};
      }
      config.data.posCode = getUrlKey('posCode')
        ? getUrlKey('posCode')
        : localStorage.getItem('posCode');
      config.data.sign = getUrlKey('sign')
        ? getUrlKey('sign')
        : localStorage.getItem('sign');
      config.data = qs.stringify(config.data);
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(res) {
      if (res.status === 200) {
        // 请求成功
        return res.data;
      } else {
        // 服务器错误
        // throw new SyntaxError("服务器错误")
        throw new SyntaxError(res.msg);
      }
    },
    function(error) {
      return Promise.reject(error);
    }
  );
};
