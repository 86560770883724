export default [
    {
        name: 'list',
        path: '/',
        title: '产品列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/list/index'),
    },
    {
        name: 'index',
        path: '/index',
        meta: {
            needLogin: false,
        },
        title: '购买选择',
        component: () => import('@/views/orderDetail/index'),
    },

    {
        name: 'specifiedVersion',
        path: '/specifiedVersion',
        title: '购买',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/list/specifiedVersion'),
    },
    {
        name: 'createStore',
        path: '/createStore',
        title: '创建我的店',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/createStore/index'),
    },
    {
        name: 'openPayment',
        path: '/openPayment',
        title: '开通收款账户',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/openPayment/index'),
    },
    {
        name: 'addInfo',
        path: '/addInfo',
        title: '开通收款账户-上传资料',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/addInfo/index'),
    },
    {
        name: 'uploadFile',
        path: '/uploadFile',
        title: '开通收款账户-上传文件',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/uploadFile/index'),
    },
    {
        name: 'result',
        path: '/result',
        title: '开通收款账户-资料提交成功',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/result/index'),
    },
    {
        name: 'serviceLicense',
        path: '/serviceLicense',
        title: '服务协议',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/serviceLicense/index'),
    },
    {
        name: 'paymentLicense',
        path: '/paymentLicense',
        title: '支付协议',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/paymentLicense/index'),
    },
    {
        name: 'try',
        path: '/try',
        title: '试用',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/try/index'),
    },
    {
        name: 'trySelect',
        path: '/trySelect',
        title: '试用门店切换',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/trySelect/index'),
    },
    {
        name: 'chooseVersion',
        path: '/chooseVersion',
        title: '选择购买版本',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/chooseVersion/index'),
    },
    {
        name: 'chooseTest',
        path: '/chooseTest',
        title: '选择版本',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/chooseTest/index'),
    },
    {
        name: 'register',
        path: '/register',
        title: '注册我的店',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/register/index'),
    },
    {
        name: 'cateringRegister',
        path: '/cateringRegister',
        title: '注册我的店',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/cateringRegister/index'),
    },
    {
        name: 'productList',
        path: '/productList',
        title: '在线购买',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/productList/index'),
    },
    {
        name: 'packageDetail',
        path: '/packageDetail',
        title: '商品详情',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/packageDetail/index'),
    },
    {
        name: 'orderList',
        path: '/orderList',
        title: '我的订单列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/orderList/index'),
    },
    {
        name: 'packageOrder',
        path: '/packageOrder',
        title: '订单详情',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/packageDetailOrder/index'),
    },
    {
        name: 'orderDetail',
        path: '/orderDetail',
        title: '订单详情',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/orderDetail/index'),
    },
    {
        name: 'storeDailyDetail',
        path: '/storeDailyDetail',
        title: '日终交易概况',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/storeDailyDetail/index'),
    },
    {
        name: 'soldDaily',
        path: '/soldDaily',
        title: '已售列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/soldDaily/soldDaily'),
    },
    {
        name: 'c',
        path: '/storeTrend',
        title: '近7日数据趋势',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/storeTrend/index'),
    },
    {
        name: 'marketRules',
        path: '/marketRules',
        title: '营销规则',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/marketRules/index'),
    },
    {
        name: 'smsConfigList',
        path: '/smsConfigList',
        title: '短信设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/smsConfigList/index'),
    },
    {
        name: 'rankAdd',
        path: '/rankAdd',
        title: '新增等级',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankAdd/index'),
    },
    {
        name: 'rankList',
        path: '/rankList',
        title: '会员等级',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankList/index'),
    },
    {
        name: 'rankUpdate',
        path: '/rankUpdate',
        title: '会员等级修改',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankUpdate/index'),
    },
    {
        name: 'rankBuyAdd',
        path: '/rankBuyAdd',
        title: '新增会籍购买活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankBuyAdd/index'),
    },
    {
        name: 'rankBuyList',
        path: '/rankBuyList',
        title: '会籍购买活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankBuyList/index'),
    },
    {
        name: 'rankBuyUpdate',
        path: '/rankBuyUpdate',
        title: '修改会籍购买活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankBuyUpdate/index'),
    },
    {
        name: 'couponList',
        path: '/couponList',
        title: '优惠券模板列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/couponList/index'),
    },
    {
        name: 'couponAdd',
        path: '/couponAdd',
        title: '新增优惠券模板',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/couponAdd/index'),
    },
    {
        name: 'couponUpdate',
        path: '/couponUpdate',
        title: '修改优惠券模板',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/couponUpdate/index'),
    },
    {
        name: 'rechargeAdd',
        path: '/rechargeAdd',
        title: '新增充值活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rechargeAdd/index'),
    },
    {
        name: 'rechargeUpdate',
        path: '/rechargeUpdate',
        title: '修改充值活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rechargeUpdate/index'),
    },
    {
        name: 'rechargeList',
        path: '/rechargeList',
        title: '充值活动列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rechargeList/index'),
    },
    {
        name: 'pointList',
        path: '/pointList',
        title: '积分兑换活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/pointList/index'),
    },
    {
        name: 'pointAdd',
        path: '/pointAdd',
        title: '新增积分活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/pointAdd/index'),
    },
    {
        name: 'pointUpdate',
        path: '/pointUpdate',
        title: '修改积分活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/pointUpdate/index'),
    },
    {
        name: 'conponChooselist',
        path: '/conponChooselist',
        title: '选择列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/conponChooselist/index'),
    },
    {
        name: 'rankchoose',
        path: '/rankchoose',
        title: '选择列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/rankchoose/index'),
    },
    {
        name: 'storeSetting',
        path: '/storeSetting',
        title: '门店设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/storeSetting/index'),
    },
    {
        name: 'addressSetting',
        path: '/storeSetting/selectAddress',
        title: '地址设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/storeSetting/selecteAddress'),
    },
    {
        name: 'cateringSetting',
        path: '/cateringSetting',
        title: '餐饮参数设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/cateringSetting/index'),
    },
    {
        name: 'groupSetting',
        path: '/groupSetting',
        title: '团购设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/groupSetting/index'),
    },
    {
        name: 'groupList',
        path: '/groupList',
        title: '团购列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/groupSetting/groupList'),
    },
    {
        name: 'groupOrderList',
        path: '/groupOrderList',
        title: '团购活动订单列表',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/groupSetting/groupOrderList'),
    },
    {
        name: 'groupVisitLog',
        path: '/groupVisitLog',
        title: '团购活动分享记录日志',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/groupSetting/groupVisitLog'),
    },
    {
        name: 'consumeCoupon',
        path: '/consumeCoupon',
        title: '消费满减',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/consumeCoupon/index'),
    },
    {
        name: 'registSetting',
        path: '/registSetting',
        title: '新人礼',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/registSetting/index'),
    },
    {
        name: 'comissionList',
        path: '/comissionList',
        title: '裂变营销',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/comissionList/index'),
    },
    {
        name: 'onBuilding',
        path: '/onBuilding',
        title: '404',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/onBuilding/index'),
    },
    {
        name: 'salesList',
        path: '/salesList',
        title: '销售管理',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/salesList/salesList'),
    },
    {
        name: 'addNewSales',
        path: '/addNewSales',
        title: '添加销售',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/salesList/addNewSales'),
    },
    {
        name: 'staffList',
        path: '/staffList',
        title: '店员管理',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/staffList/staffList'),
    },
    {
        name: 'addNewStaff',
        path: '/addNewStaff',
        title: '添加店员',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/staffList/addNewStaff'),
    },
    {
        name: 'h5Front',
        path: '/h5Front',
        title: '首页设置',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/frontListIndex'),
    },
    {
        name: 'h5FrontSwipe',
        path: '/h5FrontSwipe',
        title: '首页轮播图',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontSwipe'),
    },
    {
        name: 'h5FrontReCharge',
        path: '/h5FrontReCharge',
        title: '充值活动',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontReCharge'),
    },
    {
        name: 'h5FrontDisCard',
        path: '/h5FrontDisCard',
        title: '折扣卡',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontDisCard'),
    },
    {
        name: 'h5FrontCoupon',
        path: '/h5FrontCoupon',
        title: '优惠券',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontCoupon'),
    },
    {
        name: 'h5FrontCenterMenus',
        path: '/h5FrontCenterMenus',
        title: '中间菜单项',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontCenterMenus'),
    },
    {
        name: 'h5FrontBottomMenus',
        path: '/h5FrontBottomMenus',
        title: '底部菜单项',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontBottomMenus'),
    },
    {
        name: 'h5FrontRecProp',
        path: '/h5FrontRecProp',
        title: '推荐商品',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontRecProp'),
    },
    {
        name: 'h5DinnerList',
        path: '/h5DinnerList',
        title: '推荐商品',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5DinnerList'),
    },
    {
        name: 'h5FrontRecServie',
        path: '/h5FrontRecServie',
        title: '推荐服务项目',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontRecServie'),
    },
    {
        name: 'h5FrontProject',
        path: '/h5FrontProject',
        title: '推荐次卡套餐',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/h5Front/h5FrontProject'),
    },
    {
        name: 'qrList',
        path: '/qrList',
        title: '门店二维码',
        meta: {
            needLogin: false,
        },
        // component: () => import('@/views/test/HelloWorld.js'),
        component: () => import('@/views/qrOnload/qrList'),
    },
    {
        name: 'qrOnload',
        path: '/qrOnload',
        title: '二维码下载',
        meta: {
            needLogin: false,
        },
        component: () => import('@/views/qrOnload/index'),
    },
];
