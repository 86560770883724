import {action} from "mobx";
import {Toast} from 'antd-mobile';

const axios = require('axios');

class Try {
    /**
     * 获取验证码
     */
    @action getCode (mobile) {
        Toast.loading('加载中...', 0)
        return new Promise((resolve, reject) => {
            axios.post('/posTryout/sendMobileCode', {mobile}).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    console.log(res)
                    resolve(res)
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                console.error('err' + err)
            })
        })
    }

    /**
     * 验证优惠码
     */
    @action getCoupon(license,mobile,agentCode) {
        Toast.loading('加载中...', 0)
        return new Promise((resolve, reject) => {
            axios.post('/posTryout/validateLicense', {license,mobile,agentCode}).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    console.log(res)
                    resolve(res)
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                console.error('err' + err)
            })
        })
    }
    /**
     * 获取验证码
     */
    @action trySubmit (params) {
        console.log('params', params)
        Toast.loading('加载中...', 0)
        return new Promise((resolve, reject) => {
            axios.post('/posTryout/tryout', params).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    resolve(res)
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                console.error('err' + err)
            })
        })
    }

    /**
     * 注册
     */
    @action register (params) {
        console.log('params', params)
        Toast.loading('加载中...', 0)
        return new Promise((resolve, reject) => {
            axios.post('/posRegister/registerPos', params).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    resolve(res)
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                console.error('err' + err)
            })
        })
    }
}

export default Try
