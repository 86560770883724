import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
  //BrowserRouter as Router,
  Route,
  // Link,
  // Redirect,
  // withRouter
} from 'react-router-dom';
import 'antd-mobile/dist/antd-mobile.css';
import fastclick from 'fastclick';
// build config
import * as serviceWorker from './serviceWorker';
// axios config
import {http} from '@/common/http';
// router config
import routerConfig from '@/router/config';
import {RouterView} from '@/router';
// mobx
import {Provider} from 'mobx-react';
import CommonState from './mobx/CommonState';
import Try from './mobx/Try';

import HelloWorld from '@/views/test/HelloWorld';

const store = new CommonState();
const TryStore = new Try();
http();

fastclick.attach(document.body, {
  tapDelay: 20,
  tapTimeout: 100,
});

class App extends React.Component {
  render() {
    return (
      <Provider {...this.props}>
        <Router>
          <div className="app">
            <RouterView routes={routerConfig}></RouterView>
            {/* <Route exact component={HelloWorld} path="/" /> */}
          </div>
        </Router>
      </Provider>
    );
  }
}

ReactDOM.render(
  <App store={store} TryStore={TryStore} />,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
