import axios from 'axios';
export const urlpath = "https://inf.huitouke.cn";
export const qrUrl = 'https://order.huitouke.cn/#';
//export const urlpath = 'https://tinterface.huitouke.cn';
//export const urlpath = 'http://192.168.100.171:8888';
//export const qrUrl = 'https://torder.huitouke.cn/#';

//微信授权
export const WechatOauth = params => {
    return axios.post(urlpath + `/wechat/agentOauth`, params).then(res => res);
};

//日报表详情
export const storeDailyDetailApi = params => {
    return axios
        .post(urlpath + `/pos/storeDailyDetail`, params)
        .then(res => res);
};
export const storeDailyDetailApiV = params => {
    return axios
        .post(urlpath + `/weservice/storeDailyDetail`, params)
        .then(res => res);
};
//一周收入统计
export const storeIncomeChartApi = params => {
    return axios
        .post(urlpath + `/pos/storeIncomeChart`, params)
        .then(res => res);
};
export const storeIncomeChartApiV = params => {
    return axios
        .post(urlpath + `/weservice/storeIncomeChart`, params)
        .then(res => res);
};
//一周充值统计
export const storeRechargeChartApi = params => {
    return axios
        .post(urlpath + `/pos/storeRechargeChart`, params)
        .then(res => res);
};
export const storeRechargeChartApiV = params => {
    return axios
        .post(urlpath + `/weservice/storeRechargeChart`, params)
        .then(res => res);
};
//一周会员注册数统计
export const storeMbRegChartApi = params => {
    return axios
        .post(urlpath + `/pos/storeMbRegChart`, params)
        .then(res => res);
};
export const storeMbRegChartApiV = params => {
    return axios
        .post(urlpath + `/weservice/storeMbRegChart`, params)
        .then(res => res);
};
//一周储值支付统计
export const storeDepositPayChartApi = params => {
    return axios
        .post(urlpath + `/pos/storeDepositPayChart`, params)
        .then(res => res);
};
export const storeDepositPayChartApiV = params => {
    return axios
        .post(urlpath + `/weservice/storeDepositPayChart`, params)
        .then(res => res);
};
//热销数据
export const hotSaleApi = params => {
    return axios.post(urlpath + `/pos/hotSale`, params).then(res => res);
};
export const hotSaleApiV = params => {
    return axios.post(urlpath + `/weservice/hotSale`, params).then(res => res);
};
//商品销售列表
export const stockDailyApi = params => {
    return axios.post(urlpath + `/pos/stockDaily`, params).then(res => res);
};
export const stockDailyApiV = params => {
    return axios.post(urlpath + `/weservice/stockDaily`, params).then(res => res);
};
//项目销售列表
export const projectSoldApi = params => {
    return axios.post(urlpath + `/pos/projectSoldDaily`, params).then(res => res);
};
export const projectSoldApiV = params => {
    return axios.post(urlpath + `/weservice/projectSoldDaily`, params).then(res => res);
};
//套餐销售列表
export const packageSoldApi = params => {
    return axios
        .post(urlpath + `/pos/projectPackageSoldDaily`, params)
        .then(res => res);
};
export const packageSoldApiV = params => {
    return axios
        .post(urlpath + `/weservice/projectPackageSoldDaily`, params)
        .then(res => res);
};
//短信通知提醒开关列表
export const smsConfigListApi = params => {
    return axios.post(urlpath + `/pos/smsConfigList`, params).then(res => res);
};
//短信通知提醒开关设置
export const setMchSmsApi = params => {
    return axios.post(urlpath + `/pos/setMchSms`, params).then(res => res);
};

//添加会员等级
export const addCardRankApi = params => {
    return axios.post(urlpath + `/pos/addCardRank`, params).then(res => res);
};
// 删除会员等级
export const delebtnsApi = params => {
    return axios.post(urlpath + `/pos/delCardRank`, params).then(res => res);
};
// 修改会员等级
export const updateCardRankApi = params => {
    return axios.post(urlpath + `/pos/updateCardRank`, params).then(res => res);
};
//会员等级列表
export const cardRankListApi = params => {
    return axios.post(urlpath + `/pos/cardRankList`, params).then(res => res);
};
//根据ID查找会员等级
export const getCardRankByIdApi = params => {
    return axios
        .post(urlpath + `/pos/getCardRankById`, params)
        .then(res => res);
};

// 优惠券有效列表
export const couponTplListApi = params => {
    return axios.post(urlpath + `/pos/couponTplList`, params).then(res => res);
};
// 优惠券管理列表
export const mchCouponTplListApi = params => {
    return axios
        .post(urlpath + `/pos/mchCouponTplList`, params)
        .then(res => res);
};
// 删除优惠券
export const delCouponTplApi = params => {
    return axios.post(urlpath + `/pos/delCouponTpl`, params).then(res => res);
};
// 新增优惠券
export const addCouponApi = params => {
    return axios.post(urlpath + `/pos/addCouponTpl`, params).then(res => res);
};
// 根据ID获取优惠券
export const getCouponTplByIdApi = params => {
    return axios
        .post(urlpath + `/pos/getCouponTplById`, params)
        .then(res => res);
};
// 修改优惠券
export const updateCouponTplByIdApi = params => {
    return axios
        .post(urlpath + `/pos/updateCouponTplById`, params)
        .then(res => res);
};

// 充值活动列表
export const RechargelistApi = params => {
    return axios
        .post(urlpath + `/pos/mchRechargeDiscountList`, params)
        .then(res => res);
};


// 储值卡列表
export const GiftlistApi = params => {
    return axios
        .post(urlpath + `/pos/rechargeGiftListManage`, params)
        .then(res => res);
};
// 折扣卡列表
export const RanklistApi = params => {
    return axios
        .post(urlpath + `/pos/rankBuyListManage`, params)
        .then(res => res);
};
// 积分活动列表
export const PointlistApi = params => {
    return axios
        .post(urlpath + `/pos/pointPayListManage`, params)
        .then(res => res);
};
// 删除充值活动
export const deleteRechargeApi = params => {
    return axios
        .post(urlpath + `/pos/delRechargeDiscount`, params)
        .then(res => res);
};
// 添加充值活动
export const addRechargecountApi = params => {
    return axios
        .post(urlpath + `/pos/addRechargeDiscount`, params)
        .then(res => res);
};
// 修改充值活动
export const updateRechargeDiscountApi = params => {
    return axios
        .post(urlpath + `/pos/updateRechargeDiscount`, params)
        .then(res => res);
};
// 充值活动详情
export const getRechargeDiscountByIdApi = params => {
    return axios
        .post(urlpath + `/pos/getRechargeDiscountById`, params)
        .then(res => res);
};

// 分类列表
export const getClassListApi = params => {
    return axios
        .post(urlpath + `/pos/agentPackageClassList`, params)
        .then(res => res);
};

// 套餐详情
export const packagedetailApi = params => {
    return axios
        .post(urlpath + `/pos/agentPackageDetail`, params)
        .then(res => res);
};
// 创建套餐订单
export const CreateOrderApi = params => {
    return axios
        .post(urlpath + `/pos/agentCreateOrder`, params)
        .then(res => res);
};
// 微信支付
export const wxpayApi = params => {
    return axios
        .post(urlpath + `/pos/agentOrderWechatPay`, params)
        .then(res => res);
};
// 订单列表(套餐,开店)
export const myorderlistApi = params => {
    return axios.post(urlpath + `/pos/agentOrderList`, params).then(res => res);
};
// 订单详情(套餐,开店)
export const orderDetailApi = params => {
    return axios
        .post(urlpath + `/pos/agentOrderDetail`, params)
        .then(res => res);
};

// 创建开店订单
export const CreateStoreApi = params => {
    return axios
        .post(urlpath + `/posTryout/agentCreateStore`, params)
        .then(res => res);
};

// 积分礼包
export const marketActivityApi = params => {
    return axios
        .post(urlpath + `/pos/marketActivityList`, params)
        .then(res => res);
};
// 删除积分礼包
export const delMarketActivityApi = params => {
    return axios
        .post(urlpath + `/pos/delMarketActivity`, params)
        .then(res => res);
};
// 增加积分礼包
export const addMarketActivity = params => {
    return axios
        .post(urlpath + `/pos/addMarketActivity`, params)
        .then(res => res);
};

// 获取areaList
export const getAreaList = params => {
    return axios.post(urlpath + `/pos/getAreaList`, params).then(res => res);
};
// 获取getCityList
export const getCityList = params => {
    return axios.post(urlpath + `/pos/getCityList`, params).then(res => res);
};
// 获取provinceList
export const getProvinceList = params => {
    return axios
        .post(urlpath + `/pos/getProvinceList`, params)
        .then(res => res);
};
// 修改门店信息
export const updateStore = params => {
    return axios.post(urlpath + `/pos/updateStore`, params).then(res => res);
};
// storeInfo门店信息
export const getStoreInfo = params => {
    return axios.post(urlpath + `/pos/storeInfo`, params).then(res => res);
};

// createGroup创建团购活动
export const createGroup = params => {
    return axios.post(urlpath + `/pos/groupAdd`, params).then(res => res);
};

// groupList团购活动列表
export const groupList = params => {
    return axios.post(urlpath + `/pos/groupList`, params).then(res => res);
};

// groupUpdate修改团购活动
export const groupUpdate = params => {
    return axios.post(urlpath + `/pos/groupUpdate`, params).then(res => res);
};

// delGroup删除团购活动
export const groupDelete = params => {
    return axios.post(urlpath + `/pos/groupDelete`, params).then(res => res);
};

// groupOrderList团购活动订单列表
export const groupOrderList = params => {
    return axios.post(urlpath + `/pos/groupOrderList`, params).then(res => res);
};

// groupVisitLog团购活动分享记录日志
export const groupVisitLog = params => {
    return axios.post(urlpath + `/pos/groupVisitLog`, params).then(res => res);
};
// retailGoodsList商品管理-商品列表
export const retailGoodsList = params => {
    return axios
        .post(urlpath + `/pos/retailGoodsList`, params)
        .then(res => res);
};
// projectList项目列表
export const projectList = params => {
    return axios.post(urlpath + `/pos/projectList`, params).then(res => res);
};
// projectPackageList项目套餐包列表
export const projectPackageList = params => {
    return axios
        .post(urlpath + `/pos/projectPackageList`, params)
        .then(res => res);
};
// 注册设置优惠券
export const registSetting = params => {
    return axios.post(urlpath + `/pos/registSetting`, params).then(res => res);
};
// couponOnConsume消费满减送券设置
export const couponOnConsume = params => {
    return axios
        .post(urlpath + `/pos/couponOnConsume`, params)
        .then(res => res);
};
// comission多级分销设置
export const comission = params => {
    return axios.post(urlpath + `/pos/comission`, params).then(res => res);
};
// staffList店员列表
export const staffList = params => {
    return axios.post(urlpath + `/pos/staffList`, params).then(res => res);
};

// staffAdd店员新增
export const staffAdd = params => {
    return axios.post(urlpath + `/pos/staffAdd`, params).then(res => res);
};
// staffUpdate店员修改
export const staffUpdate = params => {
    return axios.post(urlpath + `/pos/staffUpdate`, params).then(res => res);
};
// staffDelete删除店员
export const staffDelete = params => {
    return axios.post(urlpath + `/pos/staffDel`, params).then(res => res);
};

// salesList 销售列表
export const salesList = params => {
    return axios.post(urlpath + `/pos/salesList`, params).then(res => res);
};

// addSales 销售新增
export const addSales = params => {
    return axios.post(urlpath + `/pos/addSales`, params).then(res => res);
};
// updateSales 销售修改
export const updateSales = params => {
    return axios.post(urlpath + `/pos/updateSales`, params).then(res => res);
};
// deleteSales 删除销售
export const deleteSales = params => {
    return axios.post(urlpath + `/pos/deleteSales`, params).then(res => res);
};
// h5DivGet获取H5首页模块
export const h5DivGet = params => {
    return axios.post(urlpath + `/pos/h5DivGet`, params).then(res => res);
};
// h5DivSet获取H5首页模块
export const h5DivSet = params => {
    return axios.post(urlpath + `/pos/h5DivSet`, params).then(res => res);
};
// h5OrderH5首页模块顺序
export const h5DivOrder = params => {
    return axios.post(urlpath + `/pos/h5Order`, params).then(res => res);
};
// rechargeGiftList充值优惠列表
export const rechargeGiftList = params => {
    return axios
        .post(urlpath + `/pos/rechargeGiftList`, params)
        .then(res => res);
};
// rankBuyList等级折扣列表
export const rankBuyList = params => {
    return axios.post(urlpath + `/pos/rankBuyList`, params).then(res => res);
};

// 获取门店二维码
export const getStoreQr = params => {
    return axios.post(urlpath + `/pos/getStoreQr`, params).then(res => res);
};

// 获取门店二维码
export const getWxappQr = params => {
    return axios.post(urlpath + `/wechat/getUnlimited`, params).then(res => res);
};

// 发送二维码到邮箱
export const sendStoreQrToMail = params => {
    return axios.post(urlpath + `/pos/sendStoreQrToMail`, params).then(res => res);
};
