import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';

export function RouterView({routes = []}) {
    //   console.log(routes);
    return Array.isArray(routes)
        ? routes.map(r => {
              let p = Loadable({
                  loader: r.component,
                  loading: function() {
                      return <div>Loading...</div>;
                  },
              });
              return (
                  <Route
                      key={r.name}
                      exact
                      path={r.path}
                      component={p}
                      // children={props => {
                      //   props.title = r.title;
                      // }}
                  />
              );
          })
        : null;
}
