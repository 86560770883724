import {Toast} from 'antd-mobile';
export const wechat = {
    callPay: function (webPayData, _this) {
        //alert(JSON.stringify(WeixinJSBridge))

        if (typeof window.WeixinJSBridge === "undefined" || typeof window.WeixinJSBridge === undefined) {
            if (document.addEventListener) {
                // alert("必须在微信中支付");
                Toast('必须在微信中支付');
                setTimeout(function() {
                    wechat.callPay(webPayData, _this)
                }, 50)
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(webPayData, _this), false);

            } else if (document.attachEvent) {
                Toast('必须在微信中支付');

                setTimeout(function() {
                    wechat.callPay(webPayData, _this)
                }, 50)

                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(webPayData, _this));
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(webPayData, _this));
            }
        } else {
            this.onBridgeReady(webPayData, _this);
        }
    },

    onBridgeReady: function (webPayData, _this) {
        const config = {
            "appId": webPayData.appId,     //公众号名称，由商户传入
            "timeStamp": webPayData.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": webPayData.nonceStr, //随机串
            "package": webPayData.package,
            "signType": webPayData.signType,         //微信签名方式：
            "paySign": webPayData.paySign //微信签名
        };

        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', config,
            function (res) {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    // 跳转到支付成功
                    _this.props.history.push('/result?status=success')
                    // window.location.href = window.location.host + '/result?status=success'
                }  // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            }
        );
    }
}
