import {observable, action} from 'mobx';
import {Toast} from 'antd-mobile';
import {wechat} from '../common/wechatPay';
import {observer} from 'mobx-react';

const moment = require('moment');
const axios = require('axios');
class CommonState {
    @observable list = [];
    // urlParams
    @observable posCode = '';
    @observable sign = '';
    @observable agentCode = '';
    @observable mobile = '';
    @observable platformNoArr = ['member', 'retail', 'beauty'];
    @observable currentId = '';
    @observable currentPrice = '';
    @observable currentOrderId = '';
    @observable openid = '';
    @observable ossConfig = null;
    @observable selectLocationAddress = {};
    @observable selectLocationLatLon = {};
    @observable groupEditItem = null;

    @observable h5FrontServerData = {};

    @action
    showItemInfo(index, list = null) {
        let treeList = list ? list : this.list;
        treeList.map((o, i) => {
            if (i === index) {
                o.show = o.show ? !o.show : true;
            } else {
                o.show = false;
            }
            return o;
        });
        return treeList;
    }

    @action storeParams(k, v) {
        if (k) {
            this[k] = v;
            localStorage.setItem(k, v);
        }
    }

    @action getParamsFromUrl(obj) {
        return new Promise(resolve => {
            // Object.keys(obj).map((o, i) => {
            //     this.storeParams(o, Object.values(obj)[i])
            // })
            let vals = Object.keys(obj).map(key => {
                this.storeParams(key, obj[key]);
            });
            resolve(obj);
        });
    }

    /**
     * 报价单页获取数据
     * @param platFormNo
     */
    @action listPageFetchData(platFormNo) {
        Toast.loading('加载中...');
        let data = {
            posCode: this.posCode, // pos编码
            sign: this.sign, // 密钥
            newStore: 1, // 创建新店
            platformNo: platFormNo ? platFormNo : this.platformNoArr[0], // 软件版本(member,retail,beauty三选一,分别代表会员版,零售版,美业版)
            agentCode: this.agentCode, // 代理商code(可选)
        };
        axios
            .post('/posTryout/agentSoftwareList', data)
            .then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    this.list = res.values;
                } else {
                    Toast.fail(res.msg);
                }
            })
            .catch(err => {
                console.error('err' + err);
            });
    }

    /**
     * 创建订单
     */
    @action agentCreateStore(params, _this) {
        Toast.loading('加载中...');
        let data = {
            posCode: localStorage.getItem('posCode'), // pos编码
            sign: localStorage.getItem('sign'), // 密钥
            agentCode: localStorage.getItem('agentCode'), // 代理商code(可选)
            id: localStorage.getItem('orderId'), //套餐Id
            mobile: localStorage.getItem('mobile'),
            num: 1, //套餐购买数量(默认1)
            payAmt: localStorage.getItem('price'), //支付金额(分)
            // payType: '', //支付类型,1.微信,2.支付宝
            // storeName: '', //注册店铺名称(注册门店时候需要传递)
            // userName: '', //联系人姓名(注册门店时候需要传递)
            // address: '', //联系地址(注册门店时候需要传递)
        };
        data = {...data, ...params};
        axios
            .post('/pos/agentCreateStore', data)
            .then(res => {
                Toast.hide();
                console.log(res);
                if (res.code === 200) {
                    // success
                    console.dir('agentCreateStore');
                    this.currentOrderId = res.values.order_id;
                    localStorage.setItem('payOrderId', this.currentOrderId);
                    this.currentPrice = res.values.price;

                    let reqData = {
                        posCode: this.posCode, // pos编码
                        sign: this.sign, // 密钥
                        orderId: this.currentOrderId, // 代理商code(可选)
                        openid: this.openid,
                    };

                    axios
                        .post('/pos/agentOrderWechatPay', reqData)
                        .then(r => {
                            if (r.code === 200) {
                                let wechatData = {
                                    appId: r.values.appId,
                                    nonceStr: r.values.nonceStr,
                                    package: r.values.package,
                                    paySign: r.values.paySign,
                                    signType: r.values.signType,
                                    timeStamp: r.values.timeStamp,
                                };
                                // 唤起微信支付
                                wechat.callPay(wechatData, _this);
                            } else {
                                Toast.fail(r.msg);
                            }
                        })
                        .catch(e => {
                            console.error('err' + e);
                        });
                } else {
                    Toast.fail(res.msg);
                }
            })
            .catch(err => {
                console.error('err' + err);
            });
    }

    /**
     * 获取商户信息
     */
    @action getMchInfo() {
        Toast.loading('加载中...');
        let data = {
            posCode: localStorage.getItem('posCode'), // pos编码
            sign: localStorage.getItem('sign'), // 密钥
            // id: 'A169CC692A638EA3459876A86E52C773'
        };

        return new Promise((resolve, reject) => {
            axios.post('/pos/getMchInfo', data).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    // 未交件，初审通过，合同已经签署，approved分别为0,1,2
                    sessionStorage.setItem(
                        'mchInfo',
                        JSON.stringify(res.values)
                    );
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    }

    /**
     * 获取oss信息
     */
    @action getAliOssToken() {
        // Toast.loading('加载中...')
        let _this = this;
        let data = {
            posCode: localStorage.getItem('posCode'), // pos编码
            sign: localStorage.getItem('sign'), // 密钥
        };
        function getOss() {
            axios.post('/pos/getAliOssToken', data).then(res => {
                Toast.hide();
                if (res.code === 200) {
                    // success
                    let {credentials} = res.values;
                    localStorage.setItem(
                        'credentials',
                        JSON.stringify(credentials)
                    );
                    let ossConfig = {
                        region: 'oss-cn-beijing',
                        accessKeyId: credentials.AccessKeyId,
                        accessKeySecret: credentials.AccessKeySecret,
                        bucket: 'htkwechat',
                        stsToken: credentials.SecurityToken,
                    };
                    _this.ossConfig = ossConfig;
                    return ossConfig;
                } else {
                    _this.error = res.msg;
                    return res;
                }
            });
        }

        return new Promise((resolve, reject) => {
            let tokenInfo =
                JSON.parse(localStorage.getItem('credentials')) || '';
            if (tokenInfo) {
                let {Expiration} = tokenInfo,
                    nowTime = moment().format('YYYY-MM-DD HH:mm:ss'),
                    tokenValidTime = moment(Expiration).format(
                        'YYYY-MM-DD HH:mm:ss'
                    );
                if (moment(tokenValidTime).isBefore(nowTime)) {
                    // token失效
                    let res = getOss();
                    if (res && res.msg) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                } else {
                    // token有效
                    let ossConfig = {
                        region: 'oss-cn-beijing',
                        accessKeyId: tokenInfo.AccessKeyId,
                        accessKeySecret: tokenInfo.AccessKeySecret,
                        bucket: 'htkwechat',
                        stsToken: tokenInfo.SecurityToken,
                    };
                    this.ossConfig = ossConfig;
                    resolve(ossConfig);
                }
            } else {
                // 没有tokenInfo
                let res = getOss();
                if (res && res.msg) {
                    reject(res);
                } else {
                    resolve(res);
                }
            }
        });
    }

    /**
     * 商户交件更新
     */
    @action updateMchInfoById(params, officialData = null) {
        Toast.loading('加载中...');
        let settleType = localStorage.getItem('settleType');
        let bankZ = null,
            bankF = null;
        if (+settleType === 2) {
            bankZ = localStorage.getItem('jsfsFaren-yhkz');
            bankF = localStorage.getItem('jsfsFaren-yhkf');
        } else if (+settleType === 3) {
            bankZ = localStorage.getItem('jsfsFeifaren-yhkz');
            bankF = localStorage.getItem('jsfsFeifaren-yhkf');
        }

        let extra = null;
        if (params) {
            extra = {
                industry: params.industry,
                mobile: params.mobile,
                legalName: params.legalName,
                identityCode: params.identityCode,
                runningAddress: params.runningAddress,
            };
        }

        let data = {
            posCode: localStorage.getItem('posCode') || '', // pos编码
            sign: localStorage.getItem('sign') || '', // 密钥
            mchType: localStorage.getItem('mchType') || '',
            ...officialData,
            ...extra,
            identityExpire: '',
            accountName: localStorage.getItem('accountName') || '',
            accountBank: localStorage.getItem('accountBank') || '',
            accountNum: localStorage.getItem('accountNum') || '',
            picBusinessLicense: localStorage.getItem('yyzz') || '',
            picIdentityFront: localStorage.getItem('frsfzz') || '',
            picIdentityBack: localStorage.getItem('frsfzf') || '',
            picInHand:  localStorage.getItem('frsfzsc') || '',
            picStore1: localStorage.getItem('dpzpmt') || '',
            picStore2: localStorage.getItem('dpzpsyt') || '',
            picStore3: localStorage.getItem('dpzpnj') || '',
            settleType: settleType,
            picPublicAccount: localStorage.getItem('jsfsDuigong') || '',
            picBankCardFront: bankZ,
            picBankCardBack: bankF,
            picContract1: localStorage.getItem('xy1') || '',
            picContract2: localStorage.getItem('xy2') || '',
            picContract3: localStorage.getItem('xy3') || '',
        };
        return new Promise((resolve, reject) => {
            axios
                .post('/pos/updateMchInfoById', data)
                .then(res => {
                    Toast.hide();
                    if (res.code === 200) {
                        // success
                        sessionStorage.setItem(
                            'mchInfo',
                            JSON.stringify(res.values)
                        );
                        Toast.success('提交成功');
                        resolve();
                    } else {
                        Toast.fail(res.msg);
                        reject();
                    }
                })
                .catch(err => {
                    console.error('err' + err);
                    return false;
                });
        });
    }

    /**
     * 获取订单详情
     */
    @action getOrderInfo(params) {
        Toast.loading('加载中...');
        let data = {
            posCode: localStorage.getItem('posCode'), // pos编码
            sign: localStorage.getItem('sign'), // 密钥
            orderId: localStorage.getItem('payOrderId'),
        };
        return new Promise((resolve, reject) => {
            axios
                .post('/pos/agentOrderDetail', data)
                .then(res => {
                    Toast.hide();
                    if (res.code === 200) {
                        // success
                        console.log('orderinfo', res);
                        resolve(res.values);
                    } else {
                        Toast.fail(res.msg);
                    }
                })
                .catch(err => {
                    console.error('err' + err);
                });
        });
    }

    /**
     * 获取购买商品分类
     * posCode sign agentCode
     */
    @action getAgentPackageClassList(params) {
        Toast.loading('加载中...', 0);
        return new Promise((resolve, reject) => {
            axios
                .post('/pos/agentPackageClassList', params)
                .then(res => {
                    Toast.hide();
                    if (res.code === 200) {
                        // success
                        resolve(res);
                    } else {
                        Toast.fail(res.msg);
                    }
                })
                .catch(err => {
                    console.error('err' + err);
                });
        });
    }

    /**
     * 获取购买套餐列表
     * posCode sign agentCode pageNum pageSize classId
     */
    @action getAgentPackageList(params) {
        console.log('getAgentPackageList', params);
        Toast.loading('加载中...', 0);
        return new Promise((resolve, reject) => {
            axios
                .post('/pos/agentPackageList', params)
                .then(res => {
                    Toast.hide();
                    if (res.code === 200) {
                        // success
                        resolve(res);
                    } else {
                        Toast.fail(res.msg);
                    }
                })
                .catch(err => {
                    console.error('err' + err);
                });
        });
    }
}

export default CommonState;
